import * as React from 'react'
import Header from './header'
import Footer from './footer'
import {mainContainer} from './common.module.css'

const Layout = ({ pageTitle, open, setOpen, children }) => {
  return (
    <div>
      <title>{pageTitle}</title>
      <Header open={open} setOpen={setOpen}/>
      <main className={mainContainer}>
        {children}
      </main>
      <Footer open={open} setOpen={setOpen}/>
    </div>
  )
}

export default Layout