import * as React from "react";
import { Link } from "gatsby";
import RequestDemoButton from "../components/shared/requestDemoButton";
import Logo from "../components/shared/logo";
import {
  headerContainer,
  logoContainer,
  navLinkText,
  navigationMenu,
  borderBottom,
  mobileMenu,
} from "./header.module.css";
import { text } from "./common.module.css";
import LoginButton from "./shared/LoginButton";

const Header = (props) => {
  const { open, setOpen } = props;
  return (
    <div className={headerContainer}>
      <div className={logoContainer}>
        <Logo />
      </div>
      <div>
        <nav className={navigationMenu}>
          <div>
            <Link
              to="/"
              className={`${text} ${navLinkText} ${mobileMenu}`}
              activeClassName={borderBottom}
            >
              Home
            </Link>
          </div>
          <div>
            <Link
              to="/reportGenerator"
              className={`${text} ${navLinkText} ${mobileMenu}`}
              activeClassName={borderBottom}
            >
              Document Generator
            </Link>
          </div>
          <div>
            <RequestDemoButton open={open} setOpen={setOpen} />
          </div>
          <div style={{marginLeft: "1rem", marginRight: "6px"}}>
            <LoginButton />
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
