// extracted by mini-css-extract-plugin
export var container = "footer-module--container--HXu2X";
export var logoContainer = "footer-module--logoContainer--3OEWh";
export var menuContainer = "footer-module--menuContainer--1md0A";
export var contactUsContainer = "footer-module--contactUsContainer--2iOPK";
export var heading__contactUs = "footer-module--heading__contactUs--3CwXd";
export var whiteText = "footer-module--whiteText--2UsPM";
export var margin = "footer-module--margin--O1vXJ";
export var iconMargin = "footer-module--iconMargin--Ce0WV";
export var emailBox = "footer-module--emailBox--1Sv2P";
export var copyright = "footer-module--copyright--3yIxz";
export var linkDecorationNone = "footer-module--linkDecorationNone--3RBBu";