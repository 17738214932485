import * as React from 'react'
import RequestDemoButton from '../components/shared/requestDemoButton'
import Logo from '../components/shared/logo'
import {
  container,
  logoContainer,
  menuContainer,
  contactUsContainer,
  heading__contactUs,
  whiteText,
  margin,
  iconMargin,
  emailBox,
  copyright,
  linkDecorationNone
} from './footer.module.css'
import email from '../images/email.svg'
import call from '../images/call.svg'
import {text} from './common.module.css'
import { Link } from 'gatsby'

const Footer = (props) => {
  const { open, setOpen } = props;
  return (
    <div className={`${text} ${container}`}>
      <div className={logoContainer}>
         <Logo color="white"/>
      </div>
      <div className={`${text} ${menuContainer} ${whiteText}`}>
        <div className={margin}><Link to="/" className={`${text} ${whiteText} ${linkDecorationNone}`}>HOME</Link></div>
        <div className={margin}><Link to="/reportGenerator" className={`${text} ${whiteText} ${linkDecorationNone}`}>Document Generator</Link></div>
        <div className={margin}><RequestDemoButton color="green" open={open} setOpen={setOpen}/></div>
      </div>
      <div className={`${text} ${contactUsContainer} ${whiteText}`}>
        <div className={heading__contactUs}>CONNECT WITH US</div>
        <div className={`${whiteText} ${emailBox}`}><img src={email} alt="email-icon" className={iconMargin}/>info@sncaindia.com</div>
        <div className={`${whiteText} ${emailBox}`}><img src={call} alt="call-icon" className={iconMargin}/>+044-421693112</div>
      </div>
      <div className={`${whiteText} ${copyright}`}> Copyright Ⓒ 2021 DELEGO </div>
    </div>
  )
}

export default Footer