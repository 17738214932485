import React from 'react'
import {
  demoButton, 
  demoButtonFilled,
  demoButtonOutline,
  greenBackground, 
} from './shared.module.css'

import {text} from '../common.module.css'

const RequestDemoButton = (props) => {
  const { variant, color, setOpen } = props;
  return (
    <>
    <button className={`${text} ${demoButton}  
        ${variant === "outline" ? demoButtonOutline : demoButtonFilled} ${color === "green" && greenBackground}`}
        onClick={() => setOpen(prev => !prev)}
      >
        REQUEST A DEMO
    </button>
    </>
  )
}

export default RequestDemoButton