import React from 'react'
import ReactModal from 'react-modal'
import { 
    modalContainer,
    leftContainer__modal,
    rightContainer__modal,
    delegoLogo__modal,
    subheading__modal,
    plantImage,
    plantSize,
    heading__content__modal,
    closeIconStyle,
    rightsidetext,
    formContainer,
    inputStyle,
    labelstyle,
    eachLabel,
    bookbutton,
    buttonHolder,
} from './shared.module.css'
import Logo from './logo'
import close from '../../images/closeModal.svg'
import plant from '../../images/plant.svg'
import { text } from '../common.module.css'

ReactModal.setAppElement("#___gatsby")
export default function Modal(props) {
    
    const { open, setOpen } = props;
    const onClickBookDemo = () => {
        console.log("booked yay!");
        setOpen(false);
    }

    return (
            <ReactModal isOpen={open} className={modalContainer} onRequestClose={() => setOpen(false)}>
                <div className={leftContainer__modal}>
                    <div className={heading__content__modal}>
                        <div className={delegoLogo__modal}>
                        <Logo color="white"/>
                        </div>
                        <div className={`${text} ${subheading__modal}`}>
                            Transforming Complex <br />
                            Regulations into Sustainable  <br/>
                            Reg-tech Solutions.
                        </div>
                    </div>
                    <div className={plantImage}>
                        <img src={plant} alt="plant images" className={plantSize} />
                    </div>
                </div>
                <div className={rightContainer__modal}>
                    <img src={close} alt="close icon" className={closeIconStyle} onClick={() => setOpen(false)}/>
                    <div className={`${text} ${rightsidetext}`}>
                    Want to Understand Delego Better? <br/>Give us a call. Or Request a demo. </div>
                    <div className={`${text} ${formContainer}`}>
                        <div className={eachLabel}>
                            <input type="text" name="name" required/>
                            <label htmlFor="name" for="name" className={labelstyle}>
                                <span className={inputStyle}>Name</span>
                            </label>
                        </div>
                        <div className={eachLabel}>
                            <input type="text" name="email" required/>
                            <label htmlFor="email" for="email" className={labelstyle}>
                                <span className={inputStyle}>Email</span>
                            </label>
                        </div>
                        <div className={eachLabel}>
                            <input type="text" name="number" required/>
                            <label htmlFor="number" for="number" className={labelstyle}>
                                <span className={inputStyle}>Contact No.</span>
                            </label>
                        </div>
                    </div>
                    <div className={buttonHolder}>
                        <button className={`${text} ${bookbutton}`} onClick={onClickBookDemo}>
                                Book a demo
                        </button>
                    </div>
                    
                </div>
            </ReactModal>
    )
}
