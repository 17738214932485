// extracted by mini-css-extract-plugin
export var logoStyle = "shared-module--logoStyle--zIZi0";
export var demoButton = "shared-module--demoButton--hTuA4";
export var loginBtn = "shared-module--loginBtn--Y2Hbi";
export var demoButtonFilled = "shared-module--demoButtonFilled--2XqrY";
export var greenBackground = "shared-module--greenBackground--29AfE";
export var whiteText = "shared-module--whiteText--x5Tku";
export var demoButtonOutline = "shared-module--demoButtonOutline--3ZORN";
export var modalContainer = "shared-module--modalContainer--2VeTy";
export var leftContainer__modal = "shared-module--leftContainer__modal--2oWUA";
export var rightContainer__modal = "shared-module--rightContainer__modal--3wPLc";
export var heading__content__modal = "shared-module--heading__content__modal--1-r19";
export var delegoLogo__modal = "shared-module--delegoLogo__modal--3FLiu";
export var subheading__modal = "shared-module--subheading__modal--1YIPO";
export var plantImage = "shared-module--plantImage--2SdDK";
export var plantSize = "shared-module--plantSize--14SPU";
export var closeIconStyle = "shared-module--closeIconStyle--1Dmi4";
export var rightsidetext = "shared-module--rightsidetext--DcVxh";
export var formContainer = "shared-module--formContainer--wXGaH";
export var eachLabel = "shared-module--eachLabel--2FjAp";
export var inputStyle = "shared-module--inputStyle--LmLsQ";
export var labelstyle = "shared-module--labelstyle--XagHm";
export var bookbutton = "shared-module--bookbutton--h5cUG";
export var buttonHolder = "shared-module--buttonHolder--2p_6e";