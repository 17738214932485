import React from "react";
import {
  demoButton,
  demoButtonFilled,
  demoButtonOutline,
  greenBackground,
  loginBtn
} from "./shared.module.css";

import { text } from "../common.module.css";
import { Link } from "gatsby";

const LoginButton = (props) => {
  const { variant, color, setOpen } = props;

  return (
    <>
    <Link to="https://app.delego-cs.com/" className={`${text} ${loginBtn}`} >
      <button
        className={`${text} ${demoButton}  
        ${demoButtonOutline } ${
          color === "green" && greenBackground
        }`}
      >
            Log in
      </button>
    </Link>
    </>
  );
};

export default LoginButton;
