import React from 'react'
import { Link } from 'gatsby'
import { logoStyle, whiteText } from './shared.module.css'

const Logo = ({color}) => {
  return (
    <div>
      <Link to="/" className={`${logoStyle} ${color === "white" && whiteText}`}>DELEGO</Link>
    </div>
  )
}

export default Logo